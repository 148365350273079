import React from 'react';
import Seo from '../components/seo';
import Layout from '../components/layout';
import { StaticImage } from "gatsby-plugin-image"
import Header from '../components/header';
import Footer from '../components/footer';
import Form from '../components/form';
import DinRunner from "../video/runner.mp4"
import DinBounce from "../video/bounce.mp4"
import Sudoku from "../video/sudoku.mp4"

const IndexPage = () => {

  return (
    <Layout className='homepage'>
      <Seo
        title='Welcome'
        metaDescription='Passionate game studio dedicated to creating captivating mobile gaming experiences.'
      />
      <Header/>

      <div className='hero sky'>
        
        <div className='hero__container container hero__top'>
          <div className='cloud cloud--1'>
            <StaticImage src="../images/sky1.png" alt=""/>
          </div>
          <div className='cloud cloud--2'>
            <StaticImage src="../images/sky1.png" alt=""/>
          </div>
          <h1>
            <span>Immerse yourself</span>
            <br/>
            in the twoj games
          </h1>
          <p>
            Passionate game studio dedicated to creating captivating mobile gaming experiences
          </p>
          <a className="btn btn--yellow" href='#games'>Play our games</a>
        </div>
        <div className='hero__ch container'>
          <div className='hero__ch--all'>
            <div className='hero__ch--all-item'>
              <StaticImage src="../images/ch1.png" height={150} alt="Yolker Character" />
            </div>
            <div className='hero__ch--all-item'>
              <StaticImage src="../images/ch2.png" height={150} alt="Yolker Character" />
            </div>
            <div className='hero__ch--all-item'>
              <StaticImage src="../images/ch3.png" height={150} alt="Yolker Character" />
            </div>
            <div className='hero__ch--all-item'>
              <StaticImage src="../images/ch4.png" height={150} alt="Yolker Character" />
            </div>
            <div className='hero__ch--all-item'>
              <StaticImage src="../images/ch5.png" height={150} alt="Yolker Character" />
            </div>
          </div>
          <div className='hero__ch--back'>
            <StaticImage className="trv trv--1" src="../images/trv1.png" height={150} alt="" />
            <StaticImage className="trv trv--2" src="../images/trv2.png" height={150} alt="" />
          </div>
          <div className='hero__ch--container' id="about">
            <div className='hero__ch--item'>
              <h3>
                twoj
              </h3>
              <p>
                With a team of talented and dedicated designers, programmers, and artists, we craft exceptional games that resonate with players around the globe. Whether you're an avid gamer or new to the world of interactive entertainment, we have something extraordinary in store for you.
              </p>
              <a className="btn btn--green" href='#contact'>Contact us</a>
            </div>
            <div className='hero__ch--item2'>
                <StaticImage src="../images/ndin.png" alt="Yolker Character" />
            </div>
          </div>
        </div>
      </div>
      <div className='team hidden' id='team'>
        <div className='team__container container'>
          <h2>Our core team</h2>
          <div className='team__grid team__grid--top'>
            <div className='team__grid--item'>
              <StaticImage src="../images/bro1.png" alt="Andrew Bro" />
            </div>
            <div className='team__grid--item'>
              <StaticImage src="../images/bro2.png" alt="Andrew Bro" />
            </div>
            <div className='team__grid--item'>
              <StaticImage src="../images/bro3.png" alt="Andrew Bro" />
            </div>
            <div className='team__grid--item'>
              <StaticImage src="../images/bro4.png" alt="Andrew Bro" />
            </div>
          </div>
          <div className='team__grid hidemob'>
            <p>CEO</p>
            <p>Creative Director</p>
            <p>Game Designer</p>
            <p>Game Developer</p>
          </div>
        </div>
      </div>
      <div className='games' id='games'>
        <div className='games__container container'>
          <div className='games__list'>
            <h3>twoi.io</h3>
          </div>
          <img src='../images/games.svg' alt='Games' className='games__title'/>
        </div>
      </div>
      <div className='game'>
            <div className='game__container container'>
              <div className='game__grid'>
                <div className='game__grid--item game__grid--item-video'>
                  <video autoPlay muted loop>
                    <source src={DinRunner} type="video/mp4" />
                  </video>
                </div>
                <div className='game__grid--item'>
                  <div className='game__top'>
                    <div className='game__top--icon'>
                      <StaticImage src="../images/runner3d.png" alt="Yolker Run 3D" />
                    </div>
                    <div className='game__top--title'>
                      <h4>Yolker Run 3D</h4>
                      <ul className='game__top--tags'>
                        <li>Challenge</li>
                        <li>Match 3 Colors</li>
                        <li>Casual</li>
                        <li>Arcade</li>
                      </ul>
                    </div>
                  </div>
                  <p className='game__descr'>
                    Tap into your inner gamer with this exhilarating fusion of Endless Runner and Match 3 gameplay! Elevate your cognitive prowess and enhance your focus as you embark on an infinite adventure brimming with vibrant obstacles and intricate puzzles. Engage in gem matching and amass potent power-ups while relentlessly pursuing the highest score. How enduringly can you thrive in this high-speed, captivating, and habit-forming gaming experience? Seize the moment and download now to unveil the answer!
                  </p>
                  <div className="buttons">
                     <a rel="noreferrer" target='_blank' href="https://play.google.com/store/apps/details?id=com.twoj.yolkrunner3d"><img src="/images/android.svg"/></a>
                     <a rel="noreferrer" target='_blank' href="https://apps.apple.com/us/app/yolker-run-3d/id6463488978"><img src="/images/ios.svg"/></a>
                  </div>
                </div>
              </div>
            </div>
      </div>
      <div className='game game--v2'>
            <video autoPlay muted loop className='backVideo'>
              <source src={DinBounce} type="video/mp4" />
            </video>
            <div className='game__overlay'></div>
            <div className='game__container container'>
              <div className='game__grid'>
                <div className='game__grid--item'>
                </div>
                <div className='game__grid--item'>
                  <div className='game__top'>
                    <div className='game__top--icon'>
                      <StaticImage src="../images/bouncelogo.png" alt="Yolker" />
                    </div>
                    <div className='game__top--title'>
                      <h4>Yolker</h4>
                      <ul className='game__top--tags'>
                        <li>Story</li>
                        <li>Match 3 Colors</li>
                        <li>Casual</li>
                        <li>Arcade</li>
                      </ul>
                    </div>
                  </div>
                  <p className='game__descr'>
                    Embark on a vibrant adventure with our captivating mobile game. Explore delightful levels brimming with challenges and strategize your moves to progress. As you navigate, collect power-ups, align colors, and shatter cubes to clear your path. Tread carefully; every move can change your fate. Dive deeper into the game and solve clever logic puzzles that test your wit. Along the journey, unlock amusing characters that add a touch of whimsy. With every tap, immerse yourself in an unmatched gaming journey, meticulously crafted for your enjoyment.
                  </p>
                  <div className="buttons">
                     <a rel="noreferrer" target='_blank' href="https://play.google.com/store/apps/details?id=com.twoj.yolk"><img src="/images/android.svg"/></a>
                     <a rel="noreferrer" target='_blank' href="https://apps.apple.com/us/app/yolker/id6463488891"><img src="/images/ios.svg"/></a>
                  </div>
                </div>
              </div>
            </div>
      </div>
      <div className='game sudoku'>
            <div className='game__container container'>
              <div className='game__grid'>
                <div className='game__grid--item game__grid--item-video'>
                  <video autoPlay muted loop>
                    <source src={Sudoku} type="video/mp4" />
                  </video>
                </div>
                <div className='game__grid--item'>
                  <div className='game__top'>
                    <div className='game__top--icon'>
                      <StaticImage src="../images/sudoku.png" alt="Sudoku Mix Quadoku" />
                    </div>
                    <div className='game__top--title'>
                      <h4>Sudoku Mix Quadoku</h4>
                      <ul className='game__top--tags'>
                        <li>Sudoku</li>
                        <li>Puzzle</li>
                        <li>Board</li>
                        <li>Quadoku</li>
                      </ul>
                    </div>
                  </div>
                  <p className='game__descr'>
                    The ultimate Sudoku experience that combines the traditional number puzzles with an exciting new twist – Quadoku, featuring images instead of numbers! Whether you’re a Sudoku veteran or a newcomer to the world of puzzles, Sudoku Mix offers a fresh and engaging challenge for everyone.
                  </p>
                  <div className="buttons">
                     <a rel="noreferrer" target='_blank' href="https://play.google.com/store/apps/details?id=com.twoj.sudoku"><img src="/images/android.svg"/></a>
                     <a rel="noreferrer" target='_blank' href="https://apps.apple.com/pl/app/sudoku-mix-quadoku/id6474512012"><img src="/images/ios.svg"/></a>
                  </div>
                </div>
              </div>
            </div>
      </div>

    <Form/>
    <Footer/>
    </Layout>
  )
}

export default IndexPage
